import { useMatch } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { useFeatureFlag } from '@lawnstarter/customer-modules/hooks';

export const useShowHelpIcon = () => {
  const serviceDetails = useMatch(WebRoutes.serviceDetail);
  const recommendedDetails = useMatch(WebRoutes.recommendedQuoteDetails);
  const requiredDetails = useMatch(WebRoutes.requiredQuoteDetails);
  const orderLawnTreatment = useMatch(WebRoutes.orderLawnTreatment);

  const isRefinedLawnTreatmentFlowWebEnabled = useFeatureFlag(
    FeatureFlags.ALLOW_REFINED_LAWN_TREATMENT_FLOW_WEB,
  );

  const matches = [
    serviceDetails,
    recommendedDetails,
    requiredDetails,
    isRefinedLawnTreatmentFlowWebEnabled ? orderLawnTreatment : null,
  ];

  return matches.some((match) => !!match);
};
