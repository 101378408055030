import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    primaryBold: {
      ...setFontWeight('700'),
      color: theme.colors.primary,
    },
  });

export const StyledOrderWrapper = tw.div`
  p-4 gap-6
  md:flex md:p-0
`;

export const StyledOrderDetails = tw.div`
  max-md:pt-6
  md:pl-8
`;
