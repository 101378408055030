import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  formatLocationsPressureWash,
  getMQFieldData,
  getMQServiceInfo,
  MQHowQuotingWorksModal,
  MQSubmitSuccessModal,
} from '@lawnstarter/customer-modules/helpers';
import { useFeatureFlag, useOrderServiceViewedTracking } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { ExtendedYardLocations } from '@lawnstarter/ls-constants';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { GenericQuoteForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { OrderServiceHeader } from '@src/components';
import { checkboxParser } from '@src/helpers';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';

import { StyledFormContainer } from './styles';

import type {
  GenericFormFieldData,
  GenericQuoteFormData,
} from '@lawnstarter/ls-react-common/organisms';

export function GenericQuoteServiceScreen({ serviceType }: { serviceType: ServiceType }) {
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();
  const serviceFlags = useNewServicesFlags();
  const MQStepsModal = useModal(ModalTemplate.STEPS);
  const QuoteSubmittedModal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);

  if (serviceFlags[serviceType] === false) {
    navigate(WebRoutes.services);
  }

  const apiTracking = useApiTracking()!;
  useOrderServiceViewedTracking(apiTracking, serviceType);

  const propertyId = useSelector(properties_currentPropertyIdSelector);

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<GenericQuoteFormData>();

  const serviceInfo = useMemo(() => {
    return getMQServiceInfo(serviceType);
  }, [serviceType]);

  useAutoScrollOnFormErrors({ errors, submitCount });

  const submit = async ({
    budget,
    locations,
    pendingImages,
    desired_work,
  }: GenericQuoteFormData) => {
    const handleFieldData = () => {
      if (serviceType === ServiceType.PressureWashing) {
        return {
          updatedFields: {
            property_id: propertyId,
            service_id: serviceInfo.id,
            service_str_id: serviceType,
            budget,
            pendingImages,
            desiredWorkDetails: formatLocationsPressureWash(locations, desired_work),
          },
        };
      } else {
        return {
          updatedFields: {
            property_id: propertyId,
            service_id: serviceInfo.id,
            service_str_id: serviceType,
            budget,
            pendingImages,
            desiredWorkDetails: desired_work,
            locations: checkboxParser(locations, ExtendedYardLocations),
          },
        };
      }
    };

    const result = await dispatch(orderServices_submitQuote(handleFieldData()));

    if (result?.success) {
      QuoteSubmittedModal.show(MQSubmitSuccessModal(navigate, WebRoutes.services));
      return;
    }
  };

  return (
    <StyledFormContainer>
      <OrderServiceHeader section={t('forms.quote.request')} serviceName={serviceInfo.name} />

      <div role="form" aria-labelledby="formTitle" className="flex flex-col">
        <GenericQuoteForm
          control={control}
          isUpdating={isUpdating}
          fieldData={getMQFieldData(serviceType) as GenericFormFieldData}
          infoModal={() => MQStepsModal.show(MQHowQuotingWorksModal())}
        />

        <Button
          trackID="submit"
          testID="submit"
          mode="contained"
          disabled={isUpdating}
          loading={isUpdating}
          onPress={handleSubmit(submit)}
        >
          {t('recommendations.getQuote')}
        </Button>
      </div>
    </StyledFormContainer>
  );
}

// This function is temporary and shall be removed after PE-40767
function useNewServicesFlags(): Partial<Record<ServiceType, boolean>> {
  return {
    [ServiceType.Weeding]: useFeatureFlag(FeatureFlags.ALLOW_WEED_PULLING_ORDER_WEB),
    [ServiceType.TreeTrimming]: useFeatureFlag(FeatureFlags.ALLOW_TREE_TRIMMING_ORDER_WEB),
    [ServiceType.LawnSeeding]: useFeatureFlag(FeatureFlags.ALLOW_LAWN_SEEDING_ORDER_WEB),
    [ServiceType.Sodding]: useFeatureFlag(FeatureFlags.ALLOW_SODDING_ORDER_WEB),
    [ServiceType.ShrubOrTreePlanting]: useFeatureFlag(FeatureFlags.ALLOW_TREE_PLANTING_ORDER_WEB),
    [ServiceType.PressureWashing]: useFeatureFlag(FeatureFlags.ALLOW_PRESSURE_WASHING_ORDER_WEB),
  };
}
