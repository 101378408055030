import { useWatch } from 'react-hook-form';
import { t } from '@lawnstarter/customer-modules/services';
import { ExtendedYardLocations, QuoteCleanupOptions } from '@lawnstarter/ls-constants';
import { ImageUploadInput, TextArea, TextInput } from '@lawnstarter/ls-react-common/atoms';
import { InputLayout, RadioOptionVariant } from '@lawnstarter/ls-react-common/enums';
import { CheckboxOptionsForm, RadioOptionsForm } from '@lawnstarter/ls-react-common/molecules';

import type { CleanupFormProps } from './types';

export function CleanupForm({ control }: CleanupFormProps) {
  const [debrisToRemove] = useWatch({ control, name: ['debris_to_remove'] });

  return (
    <>
      <CheckboxOptionsForm
        name="locations"
        control={control}
        rules={{ required: true }}
        options={ExtendedYardLocations}
        title={t('forms.cleanups.locations')}
      />

      <RadioOptionsForm
        noPadding
        control={control}
        name="desired_bed_cleanup"
        variant={RadioOptionVariant.Flat}
        label={t('forms.cleanups.desiredBedCleanup')}
        options={QuoteCleanupOptions.desired_bed_cleanup}
        rules={{ validate: (value) => value !== undefined && value !== null }}
      />

      <CheckboxOptionsForm
        control={control}
        name="debris_to_remove"
        rules={{ required: true }}
        options={QuoteCleanupOptions.debrisTypes}
        title={t('forms.cleanups.debrisToRemove')}
      />

      {debrisToRemove.includes('has_other') && (
        <TextArea
          label=""
          control={control}
          name="other_debris"
          rules={{ required: true }}
          placeholder={t('forms.enterDetailsHere')}
        />
      )}

      <RadioOptionsForm
        noPadding
        control={control}
        name="desired_haulaway"
        variant={RadioOptionVariant.Flat}
        label={t('forms.cleanups.desiredHaulaway')}
        options={QuoteCleanupOptions.desired_haulaway}
        rules={{ validate: (value) => value !== undefined && value !== null }}
      />

      <RadioOptionsForm
        noPadding
        control={control}
        name="last_service"
        rules={{ required: true }}
        variant={RadioOptionVariant.Flat}
        label={t('forms.cleanups.lastService')}
        options={QuoteCleanupOptions.last_service}
      />

      <TextArea name="details" control={control} label={t('forms.details')} />

      <TextInput
        name="budget"
        control={control}
        label={t('forms.budget')}
        rules={{ required: true }}
        inputLayout={InputLayout.currency}
        keyboardType="numbers-and-punctuation"
      />

      <ImageUploadInput
        control={control}
        isUploading={false}
        name="pendingImages"
        label={t('forms.uploadPhotos')}
        uploadText={t('forms.upload.uploading')}
        tooLargeFilesText={t('forms.upload.fileTooLarge')}
        addButton={{ text: t('forms.upload.placeholder') }}
      />
    </>
  );
}
