import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    accept: {
      color: theme.colors.white.primary,
    },
    bold: {
      ...setFontWeight('700'),
    },
    colorPrimary: {
      color: theme.colors.green.primary,
    },
    infoContainer: {
      backgroundColor: theme.colors.primaryLight,
      borderRadius: theme.radius.s1,
    },
    price: {
      textAlign: 'auto',
    },
    priceContext: {
      alignSelf: 'flex-end',
    },
    button: {
      width: '100%',
    },
  });
};

export const ButtonContainer = tw.div`flex flex-col gap-2 sm:flex-row sm:gap-4 sm:w-2/6`;

export const InfoContainer = tw.div`flex gap-2 p-4`;

export const PriceHeaderMainContainer = tw.div`flex flex-col gap-2`;

export const PriceHeaderSubContainer = tw.div`flex gap-2`;

export const PriceAndIconContainer = tw.div`flex flex-row gap-2`;
