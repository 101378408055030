import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    boldFont: {
      ...setFontWeight('600'),
    },
    title: {
      color: theme.colors.primary,
    },
    price: {
      marginRight: theme.spacing.s1,
    },
    divider: {
      marginTop: theme.spacing.s2,
    },
    button: {
      width: '100%',
      marginTop: theme.spacing.s8,
      marginBottom: theme.spacing.s3,
    },
  });
};

export const StyledScheduleContainer = tw.div`w-11/12 mx-auto mt-5`;

export const StyledRequestContainer = tw.div`mt-10 flex flex-col gap-2`;
