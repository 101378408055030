import {
  ManualQuoteRejectModalType,
  useManualQuoteWrapperController,
} from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';
import { ManualQuoteWrapper } from '@lawnstarter/ls-react-common/templates';

import { useRouteNavigation } from '@src/hooks';

import type { ManualQuoteInformation } from '@lawnstarter/ls-react-common/types';

export function ManualQuoteServiceWrapper({
  service,
  targetScheduleId,
}: {
  targetScheduleId: number;
  service: ManualQuoteInformation;
}) {
  const { navigate } = useRouteNavigation();
  const choiceModal = useModal(ModalTemplate.BINARY_CHOICE);
  const feedbackModal = useModal(ModalTemplate.FEEDBACK);

  const { isUpdating, actions, getModalProps } = useManualQuoteWrapperController({
    targetScheduleId,
    service,
    onAccept: () => navigate(WebRoutes.services, { options: { replace: true } }),
    onReject: () => issueQuestionModal(),
  });

  const reasonToRejectModal = () => {
    const props = getModalProps({
      modalType: ManualQuoteRejectModalType.REASON_TO_REJECT,
      confirmAction: () => navigate(WebRoutes.services, { options: { replace: true } }),
    });

    // Since we don't export the type props from the library, we need to cast it to any
    setImmediate(() => feedbackModal.show(props as any));
  };

  const talkToProModal = () => {
    const props = getModalProps({
      modalType: ManualQuoteRejectModalType.TALK_TO_PRO,
      confirmAction: () => {
        navigate(WebRoutes.conversation, {
          params: {
            conversationId: 0,
            contractorUserId: Number(service.contractor?.user?.user_id),
          },
        });
      },
      rejectAction: reasonToRejectModal,
    });

    // Since we don't export the type props from the library, we need to cast it to any
    setImmediate(() => choiceModal.show(props as any));
  };

  const backupPriceModal = () => {
    const props = getModalProps({
      modalType: ManualQuoteRejectModalType.BACKUP_PRICE,
      confirmAction: () => navigate(WebRoutes.services, { options: { replace: true } }),
      rejectAction: talkToProModal,
    });

    // Since we don't export the type props from the library, we need to cast it to any
    setImmediate(() => choiceModal.show(props as any));
  };

  const issueQuestionModal = () => {
    const props = getModalProps({
      modalType: ManualQuoteRejectModalType.ISSUE_QUESTION,
      confirmAction: service.priceBackup ? backupPriceModal : talkToProModal,
      rejectAction: talkToProModal,
    });

    // Since we don't export the type props from the library, we need to cast it to any
    choiceModal.show(props as any);
  };

  return (
    <ManualQuoteWrapper
      service={service}
      isUpdating={isUpdating}
      imagesLabel={t('servicePhotos')}
      actions={actions}
    />
  );
}
