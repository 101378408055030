import { useParams } from 'react-router-dom';
import { useConfirmCancellationController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, ScrollView } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ConfirmCancellationForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useRouteNavigation } from '@src/hooks';

import { StyledButtonContainer, StyledContainer } from './styles';

export function ConfirmCancelScreen() {
  const confirmationModal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const { navigate } = useRouteNavigation();
  const { propertyId, scheduleId } = useParams();

  const { control, handleSubmit, isLoading, cancellationFormFields, withIssueNotes } =
    useConfirmCancellationController({
      scheduleId: scheduleId as string,
      propertyId: propertyId as string,
      confirmationModal,
      onSuccess: () => {
        navigate(WebRoutes.services, {
          options: { replace: true },
        });
      },
    });

  return (
    <>
      <DetailsHeader showBackButton />
      <ScrollView>
        <StyledContainer>
          <ConfirmCancellationForm
            control={control}
            isUpdating={isLoading}
            origin="cancel_service_screen"
            fields={cancellationFormFields}
            withIssueNotes={withIssueNotes}
          />
        </StyledContainer>
      </ScrollView>
      <StyledButtonContainer>
        <Button
          trackID="confirm_cancellation_screen-confirm_cancellation"
          onPress={handleSubmit}
          mode="contained"
          loading={isLoading}
          disabled={isLoading}
        >
          {t('cancelService.confirmCancelation').toUpperCase()}
        </Button>
      </StyledButtonContainer>
    </>
  );
}
