import { useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { QuoteMulchingOptions } from '@lawnstarter/customer-modules/constants';
import { t } from '@lawnstarter/customer-modules/services';
import { YardLocations } from '@lawnstarter/ls-constants';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import {
  Button,
  DropdownInput,
  Icon,
  Text,
  TextInput,
  TouchableRipple,
} from '@lawnstarter/ls-react-common/atoms';
import { regexFilters } from '@lawnstarter/ls-react-common/filters';

import {
  style,
  StyledCustomBedButtons,
  StyledCustomBedForm,
  StyledCustomBedFormWrapper,
  StyledFlowerBedWeedingRow,
  StyledRemoveBedDesktop,
  StyledRemoveBedMobile,
} from './styles';

import type { DynamicBedsProps } from './types';

export function DynamicBedsForm({
  maxBeds,
  minBeds,
  unregister,
  control,
  setValue,
}: DynamicBedsProps) {
  const theme = useAppTheme();
  const styles = useMemo(() => style(theme), [theme]);

  const [beds, setBeds] = useState([1]);
  const formFields = useWatch({ control });
  const [customBedSizes, updateCustomBedSizes] = useState<{ value: string; label: string }[]>([]);

  const sizes = useMemo(() => {
    return customBedSizes.concat(QuoteMulchingOptions.sizes).map((size) => ({
      key: size.value,
      value: size.value,
      label: size.label,
    }));
  }, [customBedSizes]);

  const locations = useMemo(() => {
    return YardLocations.map((location: { value: string; label: string }) => ({
      key: location.value,
      value: location.value,
      label: location.label,
    }));
  }, []);

  const addBed = () => {
    setBeds((previous) => [...previous, new Date().getTime()]);
  };

  const removeBed = (index: number) => {
    const newBeds = [...beds];
    const [removedItem] = newBeds.splice(index, 1);

    setBeds(newBeds);

    // Remove form references
    unregister(`flower_bed_size_${removedItem}`);
    unregister(`flower_bed_location_${removedItem}`);
  };

  const saveCustomBed = (slot: number) => {
    const width = formFields[`custom_size_width_${slot}`];
    const length = formFields[`custom_size_length_${slot}`];

    updateCustomBedSizes((previous) => [
      { value: `${width}ftx${length}ft`, label: `${width}ft x ${length}ft` },
      ...previous,
    ]);

    // Remove form references
    unregister(`custom_size_width_${slot}`);
    unregister(`custom_size_length_${slot}`);

    // Use the new size
    setValue(`flower_bed_size_${slot}`, `${width}ftx${length}ft`);
  };

  const cancelCustomBed = (slot: number) => {
    // Update value so we can hide the form
    setValue(`flower_bed_size_${slot}`, undefined);

    // Remove form references
    unregister(`custom_size_width_${slot}`);
    unregister(`custom_size_length_${slot}`);
  };

  const renderCustomBedSizeForm = (slot: number) => {
    return (
      <StyledCustomBedFormWrapper>
        <StyledCustomBedForm>
          <TextInput
            control={control}
            filter={regexFilters.integer}
            name={`custom_size_length_${slot}`}
            keyboardType="numbers-and-punctuation"
            placeholder={t('mulching.specifyLength')}
            containerStyle={{ width: '100%', flex: 1 }}
          />
          <Text>x</Text>
          <TextInput
            control={control}
            filter={regexFilters.integer}
            name={`custom_size_width_${slot}`}
            keyboardType="numbers-and-punctuation"
            placeholder={t('mulching.specifyWidth')}
            containerStyle={{ width: '100%', flex: 1 }}
          />
        </StyledCustomBedForm>

        <StyledCustomBedButtons>
          <Button
            style={{ flex: 1 }}
            mode="contained-tonal"
            onPress={() => cancelCustomBed(slot)}
            trackID="order_mulching-cancel_custom_bed_size"
          >
            {t('cancel')}
          </Button>
          <Button
            mode="contained"
            style={{ flex: 1 }}
            onPress={() => saveCustomBed(slot)}
            trackID="order_mulching-save_custom_bed_size"
          >
            {t('save')}
          </Button>
        </StyledCustomBedButtons>
      </StyledCustomBedFormWrapper>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <Text style={{ ...setFontWeight('600') }}>{t('mulching.bedSizeAndLocation')}</Text>

      <div className="flex flex-col gap-2 mb-2">
        {beds.map((slot, index) => (
          <StyledFlowerBedWeedingRow key={slot}>
            <div className="flex flex-row">
              <Text style={{ ...setFontWeight('700') }}>#{index + 1}</Text>

              {beds.length > minBeds && (
                <StyledRemoveBedMobile>
                  <TouchableRipple onPress={() => removeBed(index)} testID="remove-bed">
                    <Text style={styles.removeBedLink}>{t('mulching.removeBed')}</Text>
                  </TouchableRipple>
                </StyledRemoveBedMobile>
              )}
            </div>

            <div className="flex-1 w-full">
              {formFields[`flower_bed_size_${slot}`] === 'custom' ? (
                renderCustomBedSizeForm(slot)
              ) : (
                <DropdownInput
                  options={sizes}
                  control={control}
                  rules={{ required: true }}
                  name={`flower_bed_size_${slot}`}
                  placeholder={t('mulching.selectSize')}
                />
              )}
            </div>

            <div className="flex-1 w-full min-w-32">
              <DropdownInput
                control={control}
                options={locations}
                rules={{ required: true }}
                name={`flower_bed_location_${slot}`}
                placeholder={t('mulching.selectLocation')}
              />
            </div>

            {beds.length > minBeds && (
              <StyledRemoveBedDesktop>
                <TouchableRipple onPress={() => removeBed(index)} testID="remove-bed">
                  <Icon name="close" color={theme.colors.error} size={theme.sizing.s5} />
                </TouchableRipple>
              </StyledRemoveBedDesktop>
            )}
          </StyledFlowerBedWeedingRow>
        ))}
      </div>

      <Button
        onPress={addBed}
        disabled={beds.length === maxBeds}
        trackID="order_mulching-add_bed_button"
      >
        {t('mulching.addOneBed')}
      </Button>
    </div>
  );
}

export function parseFlowerBeds<T extends object>(rawData: T) {
  return Object.keys(rawData)
    .reduce(
      (beds, currentKey) => {
        const match = currentKey.match(/(flower_bed_size_|flower_bed_location_)(\d+)/);
        if (match) {
          const [, prefix, id] = match;
          const property = prefix.startsWith('flower_bed_size_') ? 'size' : 'location';

          const slot = beds.find((bed) => bed.id === id);
          const value = rawData[currentKey as keyof T] as string;

          slot ? (slot[property] = value) : beds.push({ id, [property]: value });
        }
        return beds;
      },
      [] as { id: string; [key: string]: string }[],
    )
    .map(({ size, location }) => ({ size, location }));
}
