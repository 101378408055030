import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Divider } from 'react-native-paper';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_currentPropertySelector } from '@lawnstarter/customer-modules/stores/modules';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { Notice as NoticeEnum } from '@lawnstarter/ls-react-common/enums';
import { currency } from '@lawnstarter/ls-react-common/filters';
import { Notice } from '@lawnstarter/ls-react-common/organisms';

import { AerationForm } from '@src/components/organisms/forms';
import { useAutoScrollOnFormErrors, useSelector } from '@src/hooks';

import {
  StyledDesktopSubmitButtonContainer,
  StyledMobileSubmitButtonContainer,
  StyledNoticeContainer,
  StyledOrderDetails,
  StyledOrderWrapper,
  styles,
} from './styles';

import type { AerationFormData } from '@src/components/organisms/forms';
import type { Props } from './types';

export function LtrsServices({ service, submit }: Props) {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);
  const property = useSelector(properties_currentPropertySelector);

  const price = useMemo(() => {
    const { options } = property?.priced?.services?.[service.type] ?? {
      options: [{}],
    };
    const [price] = options;

    return price;
  }, [property?.priced?.services, service]);

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<AerationFormData>();

  useAutoScrollOnFormErrors({ errors, submitCount });

  const buildBulletPoints = (bulletPoint: string) => (
    <div key={bulletPoint} className="flex justify-between py-4">
      <Text variant="bodyLarge" style={{ ...setFontWeight('700') }}>
        {bulletPoint}
      </Text>
      <Icon name="check" color={theme.colors.primary} size={theme.sizing.s5} />
    </div>
  );

  const serviceExplanation = () => {
    const joinWithDivider = (result: (JSX.Element | null)[], child: string, index: number) => [
      ...result,
      buildBulletPoints(child),
      index < service.bulletPoints.length - 1 ? <Divider key={`divider-${child}`} /> : null,
    ];

    return (
      <div className="flex-1">
        <div className="mb-2">
          <Text variant="headlineSmall" style={style.primaryBold}>
            {service.name}
          </Text>
        </div>
        <div className="mb-5">
          <Text variant="bodyLarge" style={{ ...setFontWeight('400') }}>
            {service.description}
          </Text>
        </div>

        {service.bulletPoints.reduce(joinWithDivider, [])}
      </div>
    );
  };

  const formContent = () => {
    const priceSectionPrice = currency({ amount: price?.price });

    const {
      price: { recurrencyMode, title, unitCharge },
    } = service;

    const costSections = [
      { textVariant: 'headlineSmall', itens: [title, priceSectionPrice] },
      {
        textVariant: 'bodyMedium',
        itens: [recurrencyMode, unitCharge],
      },
    ];

    return (
      <StyledOrderDetails>
        {costSections.map(({ textVariant, itens }, index) => (
          <div key={`section-${index}`} className="flex justify-between">
            {itens.map((text) => (
              <Text key={text} variant={textVariant as any} style={style.primaryBold}>
                {text}
              </Text>
            ))}
          </div>
        ))}

        <div className="py-4">
          <Divider key={'divider-price'} />
        </div>

        <form>
          <div className="pb-4">
            <AerationForm control={control} isUpdating={false} />
          </div>

          <StyledNoticeContainer>
            <Notice type={NoticeEnum.Hint}>
              <div className="pt-3 pb-5 flex flex-col gap-4">
                <Text>{service.notice.firstLine}</Text>
                <Text style={{ ...setFontWeight('600') }}>{service.notice.secondLine}</Text>
              </div>
            </Notice>
          </StyledNoticeContainer>

          <StyledMobileSubmitButtonContainer>
            <Button
              mode="contained"
              loading={isUpdating}
              onPress={handleSubmit(submit)}
              trackID={`browse-screen_order-${service.type}-submit-btn`}
            >
              {t('book', { service: t(`services.${service.type}`) })}
            </Button>
          </StyledMobileSubmitButtonContainer>

          <StyledDesktopSubmitButtonContainer>
            <Button
              mode="contained"
              loading={isUpdating}
              onPress={handleSubmit(submit)}
              style={{ marginTop: theme.spacing.s5 }}
              trackID={`browse-screen_order-${service.type}-submit-btn`}
            >
              {t('book', { service: t(`services.${service.type}`) })}
            </Button>
          </StyledDesktopSubmitButtonContainer>
        </form>
      </StyledOrderDetails>
    );
  };

  return (
    <StyledOrderWrapper>
      {serviceExplanation()}
      {formContent()}
    </StyledOrderWrapper>
  );
}
