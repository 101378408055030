import { useMemo } from 'react';
import { Pressable, View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_currentPropertySelector } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Spinner, Text } from '@lawnstarter/ls-react-common/atoms';
import { currency } from '@lawnstarter/ls-react-common/filters';

import { useSelector } from '@src/hooks';

import { styles } from './styles';

export function LawnTreatmentConfirmation({
  price,
  showSchedule,
}: {
  price: string | number;
  showSchedule?: () => void;
}) {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);
  const property = useSelector(properties_currentPropertySelector);

  return (
    <>
      <Pressable
        accessibilityLabel={t('goBack')}
        onPress={showSchedule}
        style={style.goBackIconContainer}
      >
        <Icon name="arrow-left" size={theme.sizing.s6} style={style.goBackIcon} />
      </Pressable>
      <View style={style.container}>
        <Text variant="bodyMedium">{t('ltrs.orderLawnTreatmentConfirmation.serviceAddress')}</Text>

        <Text variant="bodyMedium" style={style.sessionMargin}>
          {`${property.street}, ${property.city}`}
        </Text>

        <Text variant="bodyMedium">{`${property.state} ${property.zip}`}</Text>

        <View style={style.divisor}></View>

        <Text variant="bodyMedium" style={style.sessionMargin}>
          {t('ltrs.orderLawnTreatmentConfirmation.service')}
        </Text>

        <View style={style.serviceAmountSession}>
          <Text variant="bodyMedium">{t('ltrs.orderLawnTreatmentConfirmation.serviceName')}</Text>
          {price ? (
            <Text variant="bodyMedium">
              {t('ltrs.aboutLawnTreatment.priceContainer.amountPerApplication', {
                amount: currency({ amount: price }),
              })}
            </Text>
          ) : (
            <Spinner />
          )}
        </View>

        <View style={style.servicePeriodicitySession}>
          <Text variant="bodyMedium">
            {t('ltrs.aboutLawnTreatment.priceContainer.periodicity')}
          </Text>
        </View>

        <View style={style.divisor}></View>

        <View style={style.amountDueSession}>
          <Text variant="bodyMedium" style={style.fontBold}>
            {t('ltrs.orderLawnTreatmentConfirmation.dueToday')}
          </Text>

          <Text variant="bodyMedium" style={style.fontBold}>
            {t('ltrs.orderLawnTreatmentConfirmation.dueTodayAmount')}
          </Text>
        </View>

        <Text variant="bodyMedium" style={style.sessionMargin}>
          {t('ltrs.orderLawnTreatmentConfirmation.paymentDue')}
        </Text>
        <Text variant="bodyMedium" style={style.sessionMargin}>
          {t('ltrs.orderLawnTreatmentConfirmation.serviceSchedule')}
        </Text>
      </View>
    </>
  );
}
