import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    faqInfo: {
      marginTop: theme.sizing.s3,
      marginLeft: theme.sizing.s1,
    },

    orderLawnTreatmentContainer: {
      marginTop: theme.sizing.s3,
      backgroundColor: theme.colors.white.primary,
      borderWidth: 1,
      borderRadius: 8,
      borderColor: theme.colors.gray.secondary,
      padding: theme.sizing.s6,
    },

    orderLawnTreatmentSubtitle: {
      fontWeight: '700',
      marginTop: theme.sizing.s5,
    },

    orderLawnTreatmentDescription: {
      marginTop: theme.sizing.s3,
    },

    orderLawnTreatmentItems: {
      marginTop: theme.sizing.s3,
      flexDirection: 'row',
      alignItems: 'center',
    },

    planDetailsTitle: {
      marginTop: theme.sizing.s6,
      marginBottom: theme.sizing.s2,
      fontWeight: '500',
    },

    imagesContainer: {
      marginTop: theme.sizing.s6,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    smallImagesContainer: {
      flex: 1,
      marginRight: theme.sizing.s2,
      alignItems: 'center',
    },

    smallImage: {
      height: 160,
      width: '100%',
    },

    smallImageSubtitle: {
      position: 'absolute',
      top: '110%',
      transform: [{ translateX: 0 }, { translateY: -50 }],
      color: 'white',
      textAlign: 'center',
    },

    largeImagesContainer: {
      marginTop: theme.sizing.s3,
      alignItems: 'center',
    },

    largeImage: {
      height: 400,
      width: '100%',
    },

    largeImageSubtitle: {
      position: 'absolute',
      top: '98%',
      transform: [{ translateX: 0 }, { translateY: -50 }],
      color: 'white',
      textAlign: 'center',
    },

    footer: {
      marginTop: theme.sizing.s2,
      fontWeight: '500',
      color: theme.colors.gray.tertiary,
      padding: 10,
    },

    goBackIconContainer: {
      marginTop: -theme.sizing.s9,
    },

    goBackIcon: {
      width: theme.sizing.s6,
      height: theme.sizing.s6,
      borderColor: '#E5E7EB',
      backgroundColor: '#E5E7EB',
      borderRadius: 9999,
    },
  });
};

export const StyledOrderLawnTreatmentContainer = tw.section`
flex flex-col
items-start
md:flex-row-reverse md:gap-6
`;

export const OrderLawnTreatmentContainer = tw.div`
w-full md:w-[454px] lg:w-[549px] xl:w-[713px] [@media(min-width:2000px)]:w-[900px]
`;

export const StyledFaqContainer = tw.div`
mt-20 w-full mx-auto 
md:w-8/12
`;

export const StyledOrderLawnTreatmentInfoContainer = tw.div`
mt-5 w-full mx-auto 
md:w-8/12
`;
