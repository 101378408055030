import { ENV } from '@env';
import _ from 'lodash';
import { useEffect } from 'react';
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getBrand } from '@lawnstarter/customer-modules/helpers';
import {
  ApiTrackingProvider,
  AppProvider,
  ModalProvider,
} from '@lawnstarter/ls-react-common/contexts';
import { Brand } from '@lawnstarter/ls-react-common/enums';

import { GlobalErrorRenderer } from '@src/components';
import { StripeProvider } from '@src/contexts';
import { useHotjar, useTrackIdentify } from '@src/hooks';
import { CSSBindTheme } from '@src/layout';
import { GlobalRouter } from '@src/routers';
import { apiTrackingService } from '@src/services';
import store from '@src/store';

export const FaviconId = 'head-favicon';

export default function App() {
  const brand = getBrand();

  useEffect(() => {
    const env = ENV !== 'production' ? `${_.capitalize(ENV)} @ ` : '';
    document.title = `${env}${brand.name} - Customer`;

    if (!document.querySelector("link[rel~='icon']")) {
      const link = document.createElement('link');
      link.id = FaviconId;
      link.rel = 'shortcut icon';
      link.href = `/src/assets/${brand.slug}/favicon.png`;

      document.head.appendChild(link);
    }
  }, [brand]);

  // Initialize Hotjar
  useHotjar(101160, 6);

  useTrackIdentify({
    userId: 814,
    anonymousId: uuidv4(),
    // Data to be forwarded to FullStory
    traits: {
      name: 'Jhon',
      email: 'jhon@ls.com',
    },
  });

  return (
    <AppProvider brand={brand.slug as Brand}>
      <ApiTrackingProvider apiTrackingService={apiTrackingService}>
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
          <Provider store={store}>
            <StripeProvider>
              <ModalProvider>
                <CSSBindTheme />

                <GlobalRouter />

                <GlobalErrorRenderer />
              </ModalProvider>
            </StripeProvider>
          </Provider>
        </SafeAreaProvider>
      </ApiTrackingProvider>
    </AppProvider>
  );
}
