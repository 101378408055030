import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';

import { errorService } from '@src/services';

import type { Parameter } from '@lawnstarter/ls-react-common/types';
import type { NavigateOptions } from 'react-router/dist/lib/context';

interface RouteNavigationProps {
  params?: Parameter;
  options?: NavigateOptions;
}

// Used to clean array of repeated elements and filter for actual values
const cleanArraySet = (items: RegExpMatchArray | [] | null) =>
  [...new Set(items ?? [])].filter((i) => i);

export const useRouteNavigation = () => {
  const domNavigate = useNavigate();

  const parseRoute = (path: WebRoutes, { params }: Omit<RouteNavigationProps, 'options'> = {}) => {
    params = params ?? {};
    if (Object.keys(params).some((key) => key.includes('_'))) {
      params = _.mapKeys(params, (__, key) => _.camelCase(key));
    }

    const logError = (message: string) => {
      errorService.log({
        showAlert: true,
        extra: { path, params },
        error: { message },
      });

      return WebRoutes.noNavigation;
    };

    if (!Object.values(WebRoutes).includes(path)) {
      return logError('The requested route was not found');
    }

    let parsedRoute: string = path;
    const paramKeys = Object.keys(params);

    // Check for optional params on the route and remove them if not provided
    const optionalParams = cleanArraySet(parsedRoute.match(/(:[A-Za-z]+[0-9]*\?)+/g));
    if (optionalParams.length > 0) {
      for (const key of optionalParams) {
        const cleanKey = key.replace('?', '').replace(':', '');

        parsedRoute = paramKeys.includes(cleanKey)
          ? parsedRoute.replace(key, params[cleanKey].toString())
          : parsedRoute.replace(`/${key}`, '');
      }
    }

    // Validate if route have mandatory params and check if the provided params have the keys
    const mandatoryParams = cleanArraySet(parsedRoute.match(/(:[A-Za-z]+[0-9]*)+/g));
    if (mandatoryParams.length > 0) {
      if (paramKeys.length === 0) {
        const requiredString = mandatoryParams.map((p) => p.replace(':', '')).join(', ');
        return logError(`Mandatory route params not found: [${requiredString}]`);
      }

      for (const key of mandatoryParams) {
        const cleanKey = key.replace(':', '');
        if (!paramKeys.includes(cleanKey)) {
          return logError(`Mandatory route param not found: ${cleanKey}`);
        }

        parsedRoute = parsedRoute.replace(key, params[cleanKey]?.toString());
      }
    }

    // Returns parsed route path
    return parsedRoute as WebRoutes;
  };

  const navigate = (path: WebRoutes, { params, options }: RouteNavigationProps = {}) => {
    if (path === WebRoutes.back) {
      return domNavigate(-1);
    }

    const route = parseRoute(path, { params });
    if (route !== WebRoutes.noNavigation) {
      domNavigate(route, options);
    }
  };

  return { parseRoute, navigate };
};
