import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { setCurrentProperty } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import {
  properties_currentPropertySelector,
  properties_propertiesInArraySelector,
  properties_updateCurrentProperty,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { EntityList } from '@lawnstarter/ls-react-common/organisms';

import { useDispatch, useSelector } from '@src/hooks';

import { styles } from './styles';

import type { Property } from '@lawnstarter/ls-react-common/types';

export const PropertyList = ({
  onToggleView,
  onClose,
}: {
  onToggleView: () => void;
  onClose: () => void;
}) => {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);
  const dispatch = useDispatch();

  const properties = useSelector(properties_propertiesInArraySelector);
  const selectedProperty = useSelector(properties_currentPropertySelector);

  const itemExtractor = useCallback(
    ({ id, street, city, state, zip }: Property) => ({
      key: id,
      label: (
        <View style={style.label}>
          <Text variant="bodyMedium">{street}</Text>
          <Text variant="bodyMedium">{`${city}, ${state} ${zip}`}</Text>
        </View>
      ),
    }),
    [style.label],
  );

  return (
    <EntityList
      title={t('propertySelect')}
      entities={properties}
      itemExtractor={itemExtractor}
      newEntityText={t('addPropertyAddress')}
      onNewEntityRequest={onToggleView}
      selected={selectedProperty}
      onEntitySelect={({ id: property_id }) => {
        dispatch(properties_updateCurrentProperty({ property_id }));
        setCurrentProperty({ propertyId: property_id });
        onClose();
      }}
    />
  );
};
