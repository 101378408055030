import BottomSheet from '@devvie/bottom-sheet';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import tw from 'tailwind-styled-components';
import { eventEmitter } from '@lawnstarter/customer-modules/helpers';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';

import { useScrollBlock } from '@src/hooks';
import { MobileHeaderEvents } from '@src/layout/Header/MobileHeader/MobileHeaderEvents';

import { styles } from './styles';

import type { BottomSheetMethods } from '@devvie/bottom-sheet';

interface HelperBottomSheetProps {
  content: React.ReactNode;
  title: React.ReactNode;
}

export const HelperBottomSheet = ({ content, title }: HelperBottomSheetProps) => {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const [blockScroll, allowScroll] = useScrollBlock();
  const [sheetVisibility, updateSheetVisibility] = useState(false);
  const sheetRef = useRef<BottomSheetMethods>(null);

  // Listen to open requests
  useEffect(() => {
    const helpDeskSelectPress = () => {
      sheetRef.current?.open();
      blockScroll();
    };

    const source = eventEmitter.on(
      MobileHeaderEvents.onHelpDeskSelectPressEvent,
      helpDeskSelectPress,
    );

    return () => {
      source.removeListener(MobileHeaderEvents.onHelpDeskSelectPressEvent, helpDeskSelectPress);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeBottomSheet = useCallback(() => {
    sheetRef.current?.close();
    allowScroll();
  }, [allowScroll]);

  return (
    <StyledHelpDeskBottomSheetWrapper $sheetVisibility={sheetVisibility}>
      <BottomSheet
        height={'60%'}
        ref={sheetRef}
        style={style.sheetContainer}
        onAnimate={(animated) => {
          // Avoid weird visual behaviors
          if (animated === 0 && sheetVisibility) {
            updateSheetVisibility(false);
          } else if (animated > 0 && !sheetVisibility) {
            updateSheetVisibility(true);
          }
        }}
        onClose={() => allowScroll()}
        android_closeOnBackPress={false}
      >
        <ScrollView
          contentContainerStyleOverride={{
            overflowY: 'scroll',
            height: 300,
            overflowX: 'hidden',
            padding: 0,
          }}
        >
          <View style={style.header}>
            <Text variant="headlineSmall" style={style.headerTitle}>
              {title}
            </Text>

            <Button
              trackID="close-help-desk"
              onPress={closeBottomSheet}
              style={style.closeButtonLabel}
            >
              <Icon name="close" size={26} />
            </Button>
          </View>
          {content}
        </ScrollView>
      </BottomSheet>
    </StyledHelpDeskBottomSheetWrapper>
  );
};

const StyledHelpDeskBottomSheetWrapper = tw.div<{ $sheetVisibility: boolean }>`
  ${(params) => (params.$sheetVisibility ? 'z-10' : '-z-10')}
  fixed top-0 right-0 left-0 h-full
`;
