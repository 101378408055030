import tw from 'tailwind-styled-components';

export const StyledContainer = tw.div`
  max-w-4xl
  max-md:mx-4 max-md:mt-4
`;

export const StyledFormContainer = tw.div`
  max-w-lg
`;
