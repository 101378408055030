import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { GestureResponderEvent, Pressable } from 'react-native';
import { Divider } from 'react-native-paper';
import { t } from '@lawnstarter/customer-modules/services';
import { getLongGrassPolicy } from '@lawnstarter/ls-constants';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { Notice as NoticeType, RadioOptionVariant } from '@lawnstarter/ls-react-common/enums';
import { currency, frequency } from '@lawnstarter/ls-react-common/filters';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import {
  CheckboxOptionsForm,
  RadioOptionsForm,
  SelectOption,
} from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate, Notice } from '@lawnstarter/ls-react-common/organisms';
import { Tooltip } from '@lawnstarter/ls-react-web-common';

import { DatePickerForm } from '@src/components/atoms';

import { PaymentForm } from '../PaymentForm';
import { style, StyledSummary } from './styles';

import type { LawnMowingFormProps } from './types';

export function LawnMowingForm({
  cycles,
  control,
  minDate,
  setValue,
  isUpdating,
  submitCount,
  currentCard4Digits,
}: LawnMowingFormProps) {
  const theme = useAppTheme();
  const styles = useMemo(() => style(theme), [theme]);

  const noCardFound = useMemo(() => currentCard4Digits.length === 0, [currentCard4Digits]);
  const [shouldAddNewCard, setShouldAddNewCard] = useState(false);

  useEffect(() => {
    setShouldAddNewCard(noCardFound);
  }, [noCardFound]);

  const currentCycleId = useWatch({ control, name: 'cycle' });
  const longGrassPolicyModal = useModal(ModalTemplate.MESSAGES);

  const currentCycle = useMemo(
    () => cycles.find((cycle) => cycle.value.id === currentCycleId)?.value,
    [currentCycleId, cycles],
  );

  const renderTrustAndSafetyFee = () => {
    const { trust_fee = null } = currentCycle ?? {};

    if (!trust_fee) {
      return null;
    }

    return (
      <div className="flex justify-end gap-4">
        <div className="flex flex-row items-center">
          <Text style={styles.label}>{`+ ${t('trust_and_safety_fee')}`}</Text>
          <Tooltip title={t('trust_and_safety_fee_description')} arrow>
            <div>
              <Icon
                size={theme.sizing.s5}
                name="help-circle-outline"
                color={theme.colors.primary}
                style={{ marginLeft: theme.spacing.s1 }}
              />
            </div>
          </Tooltip>
        </div>
        <Text style={styles.value}>{currency({ amount: trust_fee })}</Text>
      </div>
    );
  };

  const totalAmount = useMemo(() => {
    const { total_amount = undefined, trust_fee = undefined } = currentCycle ?? {};

    return total_amount + trust_fee;
  }, [currentCycle]);

  const renderTotal = useCallback(() => {
    return (
      <div className="flex flex-col justify-end mt-2">
        <div className="flex justify-end flex-row gap-x-4">
          <Text style={styles.label}>{t('total')}</Text>
          <Text style={styles.value}>
            {currency({
              amount: totalAmount,
            })}
          </Text>
        </div>
        <div className="flex justify-end">
          <Text style={{ fontStyle: 'italic' }}>{t('perService')}</Text>
        </div>
      </div>
    );
  }, [totalAmount, styles.label, styles.value]);

  const renderCurrentCard = useCallback(() => {
    if (noCardFound) {
      return;
    }

    return (
      <>
        <Text style={styles.inputLabel}>{t('payment.choosePayment')}</Text>
        <SelectOption
          disabled={isUpdating}
          selected={shouldAddNewCard ? 'add-card' : 'current-card'}
          onSelect={(key) => {
            const addCard = key === 'add-card';

            setShouldAddNewCard(addCard);
            setValue('payment_method', addCard ? '' : currentCard4Digits);
          }}
          options={[
            { key: 'current-card', label: t('cardEndingIn', { last4: currentCard4Digits }) },
            { key: 'add-card', label: t('addPayment') },
          ]}
        />
      </>
    );
  }, [currentCard4Digits, isUpdating, noCardFound, setValue, shouldAddNewCard, styles.inputLabel]);

  const openLongGrassPolicy = (ev: GestureResponderEvent) => {
    ev.preventDefault();

    longGrassPolicyModal.show({
      origin: 'mowing_reactivation',
      messages: [t('long_grass.policy.label'), ...getLongGrassPolicy()],
    });
  };

  return (
    <>
      <RadioOptionsForm
        noPadding
        name="cycle"
        control={control}
        rules={{ required: true }}
        variant={RadioOptionVariant.Flat}
        label={t('reactivateService.chooseMowingFrequency')}
        options={cycles.map(({ label, value }) => ({ label, value: value.id }))}
      />

      <div className="flex flex-col">
        <Text style={styles.inputLabel}>{t('reactivateService.preferredStartDate')}</Text>
        <DatePickerForm control={control} name="start_date" minDate={new Date(minDate)} />
        <Text style={{ ...styles.inputLabel, textAlign: 'right' }}>
          {t('reactivateService.minimumBusinessDaysRequired')}
        </Text>
      </div>

      {renderCurrentCard()}

      {shouldAddNewCard && (
        <div data-testid="payment-form">
          <PaymentForm
            isRequired
            control={control}
            disabled={isUpdating}
            submitCount={submitCount}
            name="is_stripe_ready"
          />
        </div>
      )}

      <div className="flex flex-row">
        <StyledSummary>
          <div className="gap-4 flex flex-col pb-2">
            <Text
              variant="titleMedium"
              style={{
                ...styles.label,
                color: theme.colors.primary,
              }}
            >
              {t('summary')}
            </Text>
            <div className="flex justify-end gap-4">
              <Text variant="bodyMedium" style={{ color: theme.colors.primary, ...styles.label }}>
                {t('mowingCycle', { cycle: frequency({ cycle: currentCycle?.cycle }) })}
              </Text>
              <div>
                {localStorage.getItem('deal') && (
                  <Text style={styles.fullValue}>{`$${currentCycle?.price}`}</Text>
                )}
                <Text style={styles.value}>{`$${currentCycle?.total_amount}`}</Text>
              </div>
            </div>
            {renderTrustAndSafetyFee()}
          </div>

          <Divider />

          {renderTotal()}
        </StyledSummary>
      </div>

      {localStorage.getItem('deal') && (
        <Notice type={NoticeType.Hint}>
          <div className="pt-3 pb-5 flex flex-col gap-1">
            <Text variant="bodyLarge" style={{ ...setFontWeight('700') }}>
              {t('specialDeal.title')}
            </Text>
            <Text style={{ ...setFontWeight('600') }}>{t('specialDeal.welcome')}</Text>
            <Text>
              {t('specialDeal.resumeServiceFor', { price: currency({ amount: totalAmount }) })}
            </Text>
          </div>
        </Notice>
      )}

      <CheckboxOptionsForm
        name="long_grass"
        control={control}
        rules={{ required: true }}
        options={[
          {
            value: 'ToS',
            label: (
              <Text style={{ display: 'flex' }}>
                {t('long_grass.policy.agreement.iAgree')}{' '}
                <Pressable onPress={openLongGrassPolicy}>
                  <Text
                    style={{
                      color: theme.colors.blue.primary,
                      ...setFontWeight('700'),
                    }}
                  >
                    {t('long_grass.policy.agreement.brandPolicy')}
                  </Text>
                </Pressable>
              </Text>
            ),
          },
        ]}
      />
    </>
  );
}
