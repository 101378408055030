import tw from 'tailwind-styled-components';

export const StyledResponsiveContentWrapper = tw.div<{ $withContent: boolean }>`
  flex grow ${(params) => (params.$withContent ? 'justify-between' : 'justify-center')}
  md:grow-0
`;

const StyledResponsivePanelWrapper = tw.div<{ $hideContent: boolean; $withContent: boolean }>`
  flex flex-col 
  max-md:mx-auto max-md:w-[90%] 
  md:w-6/12 md:max-h-[70vh] md:rounded md:border-[var(--graySecondary)] md:overflow-hidden md:overflow-y-auto
  
  ${(params) =>
    params.$withContent
      ? ` bg-whitePrimary
          md:border `
      : ` bg-transparent [&_div]:!text-center
          max-md:flex max-md:h-[55vh] `}
  
  ${(params) =>
    params.$hideContent &&
    ` max-md:hidden
      md:block `}
`;

export const StyledLeftPanel = tw(StyledResponsivePanelWrapper)`
  ${({ $withContent }) =>
    $withContent &&
    ` max-md:mx-auto
      md:mr-8
      lg:w-5/12 `}
`;

export const StyledRightPanel = tw(StyledResponsivePanelWrapper)`
  flex grow
  
  ${(params) =>
    params.$withContent
      ? ` block 
          lg:w-7/12 `
      : ` hidden
          lg:w-0 `}
`;
