import { useMemo } from 'react';
import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Spinner, Text } from '@lawnstarter/ls-react-common/atoms';
import { currency } from '@lawnstarter/ls-react-common/filters';

import { styles } from './styles';

export function OrderLawnTreatmentFooter({
  price,
  submitRequest,
  isLoading = false,
}: {
  price: string | number;
  submitRequest: () => void;
  isLoading?: boolean;
}) {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  return (
    <View style={style.footerContainer}>
      <View />
      <View style={style.priceTop}>
        <Text allowFontScaling={false} style={style.priceBold}>
          {price ? (
            t('ltrs.aboutLawnTreatment.priceContainer.amountPerApplication', {
              amount: currency({ amount: price }),
            })
          ) : (
            <Spinner />
          )}
        </Text>
        <Text allowFontScaling={false}>
          {t('ltrs.aboutLawnTreatment.priceContainer.periodicityAlternative')}
        </Text>
      </View>
      <Button
        trackID="order_lawn_treatment_footer-cta"
        onPress={submitRequest}
        loading={isLoading}
        buttonColor={theme.colors.primary}
        textColor={theme.colors.onPrimary}
        style={style.footerButton}
      >
        {t('ltrs.aboutLawnTreatment.priceContainer.callToAction')}
      </Button>
      <Text style={style.priceFooterText}>
        {t('ltrs.aboutLawnTreatment.priceContainer.footer')}
      </Text>
    </View>
  );
}
