import { View } from 'react-native';
import {
  ProQuoteRejectModalType,
  useProQuoteController,
} from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';
import { ProQuoteWrapper } from '@lawnstarter/ls-react-common/templates';

import { useRouteNavigation } from '@src/hooks';

import type { RequiredServiceInformation } from '@lawnstarter/ls-react-common/types';
import type { ViewProps } from 'react-native';

export function RequiredServiceWrapper({
  service,
  ...props
}: ViewProps & {
  service: RequiredServiceInformation;
}) {
  const { navigate } = useRouteNavigation();

  const choiceModal = useModal(ModalTemplate.BINARY_CHOICE);
  const feedbackModal = useModal(ModalTemplate.FEEDBACK);

  const { isUpdating, actions, getModalProps } = useProQuoteController({
    service,
    source: 'required_service',
    onAccept: ({ route, params }) => navigate(route, { params }),
    onReject: () => talkToProModal(),
  });

  const reasonToRejectModal = () => {
    const props = getModalProps({
      confirmAction: () => navigate(WebRoutes.services, { options: { replace: true } }),
      modalType: ProQuoteRejectModalType.REASON_TO_REJECT,
    });

    // Since we don't export the type props from the library, we need to cast it to any
    setImmediate(() => feedbackModal.show(props as any));
  };

  const talkToProModal = () => {
    const props = getModalProps({
      modalType: ProQuoteRejectModalType.TALK_TO_PRO,
      confirmAction: () => {
        navigate(WebRoutes.conversation, {
          params: {
            conversationId: 0,
            contractorUserId: Number(service.contractor?.user?.user_id),
          },
        });
      },
      rejectAction: reasonToRejectModal,
    });

    // Since we don't export the type props from the library, we need to cast it to any
    setImmediate(() => choiceModal.show(props as any));
  };

  return (
    <View {...props}>
      <ProQuoteWrapper
        source="required_service"
        isPendingApproval
        service={service}
        isUpdating={isUpdating}
        buttons={actions}
        labels={{
          serviceNotes: t('services.notes.title'),
          datePendingYourApproval: t('quote.datePendingYourApproval'),
        }}
      />
    </View>
  );
}
