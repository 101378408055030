import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme) =>
  StyleSheet.create({
    removeBedLink: {
      textDecorationLine: 'underline',
      color: theme.colors.blue.primary,
    },
  });

export const StyledFlowerBedWeedingRow = tw.div`
  flex flex-col gap-4 items-center
  md:flex-row
`;

export const StyledCustomBedFormWrapper = tw.div`
  flex flex-col gap-2 border border-graySecondary px-1 pb-1 rounded
`;

export const StyledCustomBedForm = tw.div`
  flex flex-row gap-2 items-center justify-center
  md:w-full
`;

export const StyledCustomBedButtons = tw.div`
  flex flex-row gap-2 justify-center mt-2
  md:justify-end md:mt-0
`;

export const StyledRemoveBedDesktop = tw.div`
  hidden
  md:flex
`;

export const StyledRemoveBedMobile = tw.div`
  flex ml-2 uppercase
  md:hidden
`;
