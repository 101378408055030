import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common/helpers';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme) =>
  StyleSheet.create({
    title: {
      marginBottom: theme.spacing.s4,
      ...setFontWeight('600'),
    },
    availability: {
      marginBottom: theme.spacing.s4,
      ...setFontWeight('500'),
    },
  });

export const StyledContainer = tw.div`
  p-2 flex flex-col gap-4 h-full
  md:p-6 md:pt-0 md:h-auto
`;
