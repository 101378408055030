import { useWatch } from 'react-hook-form';
import { QuoteMulchingOptions } from '@lawnstarter/customer-modules/constants';
import { t } from '@lawnstarter/customer-modules/services';
import { ImageUploadInput, TextArea, TextInput } from '@lawnstarter/ls-react-common/atoms';
import { InputLayout, RadioOptionVariant } from '@lawnstarter/ls-react-common/enums';
import { RadioOptionsForm } from '@lawnstarter/ls-react-common/molecules';

import { DynamicBedsForm } from '../DynamicBedsForm';

import type { MulchingFormProps } from './types';

export function MulchingForm({ control, ...props }: MulchingFormProps) {
  const [desiredType, removeMulch] = useWatch({ control, name: ['desired_type', 'remove_mulch'] });

  return (
    <>
      <DynamicBedsForm control={control} {...props} />

      <RadioOptionsForm
        noPadding
        control={control}
        name="desired_sheeting"
        rules={{ required: true }}
        variant={RadioOptionVariant.Flat}
        label={t('mulching.desiredSheeting')}
        options={QuoteMulchingOptions.desiredSheeting}
      />

      <RadioOptionsForm
        noPadding
        control={control}
        name="desired_type"
        rules={{ required: true }}
        label={t('mulching.desiredType')}
        variant={RadioOptionVariant.Flat}
        options={QuoteMulchingOptions.desiredType}
      />

      {desiredType === 'other' && (
        <TextArea
          control={control}
          rules={{ required: true }}
          name="desired_type_other_details"
          label={t('mulching.desiredTypeOther')}
        />
      )}

      <RadioOptionsForm
        noPadding
        control={control}
        name="desired_color"
        rules={{ required: true }}
        variant={RadioOptionVariant.Flat}
        label={t('mulching.desiredColor')}
        options={QuoteMulchingOptions.desiredColor}
      />

      {/* This value also affects haul_mulch */}
      <RadioOptionsForm
        noPadding
        control={control}
        name="remove_mulch"
        label={t('mulching.removeMulch')}
        variant={RadioOptionVariant.Flat}
        options={QuoteMulchingOptions.removeMulch}
        rules={{ validate: (value) => value !== undefined && value !== null }}
      />

      {removeMulch && (
        <RadioOptionsForm
          noPadding
          control={control}
          name="haul_mulch"
          label={t('mulching.haulMulch')}
          variant={RadioOptionVariant.Flat}
          options={QuoteMulchingOptions.haulMulch}
          rules={{ validate: (value) => value !== undefined && value !== null }}
        />
      )}

      <RadioOptionsForm
        noPadding
        control={control}
        name="self_purchase_materials"
        variant={RadioOptionVariant.Flat}
        label={t('mulching.selfPurchaseMaterials')}
        options={QuoteMulchingOptions.selfPurchaseMaterials}
        rules={{ validate: (value) => value !== undefined && value !== null }}
      />

      <TextArea
        name="details"
        control={control}
        label={t('forms.details')}
        placeholder={t('forms.enterDetailsHere')}
      />

      <TextInput
        name="budget"
        control={control}
        rules={{ required: true }}
        inputLayout={InputLayout.currency}
        label={t('forms.budget')}
        placeholder={t('forms.placeholders.currency')}
        keyboardType="numbers-and-punctuation"
      />

      <ImageUploadInput
        control={control}
        isUploading={false}
        name="pendingImages"
        label={t('forms.uploadPhotos')}
        uploadText={t('forms.upload.uploading')}
        tooLargeFilesText={t('forms.upload.fileTooLarge')}
        addButton={{ text: t('forms.upload.placeholder') }}
      />
    </>
  );
}
