import { useMemo, useState } from 'react';
import { Image, ImageSourcePropType, SafeAreaView, ScrollView, View } from 'react-native';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import LawnTreatmentMonth1Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_1.png';
import LawnTreatmentMonth5Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_5.png';
import LawnTreatmentMonth11Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_11.png';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  auth_signedUserSelector,
  orderServices_orderPricedLtrsService,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { ScheduleLawnTreatment } from '@src/components';
import { OrderLawnTreatmentFooter } from '@src/components/molecules/AboutLawnTreatment/footer/OrderLawnTreatmentFooter';
import { PlanDetailsTable } from '@src/components/molecules/Table/PlanDetailsTable.tsx';
import { StyledCarouselContainer } from '@src/components/organisms/RecommendationCarousel/styles.ts';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';
import { useWindowSize } from '@src/hooks/useWindowSize';

import { StyledOrderLawnTreatmentInfoContainer, styles } from './MobileStyles';

export function MobileOrderLawnTreatmentTemplate() {
  const [showSchedule] = useState(false);
  const { email: customerEmail } = useSelector(auth_signedUserSelector);
  const { navigate } = useRouteNavigation();
  const { propertyId, servicePromoId } = useParams();
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);
  const modal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const dispatch = useDispatch();

  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const lawnTreatmentInfo = useSelector(
    ({ properties }) =>
      properties.propertiesById[propertyId as string]?.priced?.services?.fertilization,
  );

  const { width } = useWindowSize();

  const onSubmit = async () => {
    const { success, error } = await dispatch(
      orderServices_orderPricedLtrsService({
        property_id: propertyId,
        service_promo_id: servicePromoId,
        service_id: lawnTreatmentInfo?.service.id,
        price_id: lawnTreatmentInfo?.options[0].price_id,
      }),
    );

    if (success) {
      const message = t('confirmationServiceOrder.message', {
        customerEmail,
        serviceName: lawnTreatmentInfo?.service.name,
      });
      modal.show({
        icon: 'checkbox-marked-circle-plus-outline',
        action: t('confirmationServiceOrder.title'),
        messages: [message],
        buttonText: t('goToMyServices'),
        onDismiss: () => navigate(WebRoutes.services, { options: { replace: true } }),
      });
    } else {
      modal.show({
        icon: 'block-helper',
        action: t('somethingWentWrong'),
        messages: [error?.error?.message ?? t('apiGenericError')],
        buttonText: t('tryAgain'),
      });
    }
  };

  const getCarouselItems = () => {
    return [
      {
        title: '',
        imageUrl: LawnTreatmentMonth1Photo,
        text: t('ltrs.orderLawnTreatment.imageSubtitles.monthOne'),
        actionText: '',
        action: { routeName: '' },
      },
      {
        title: '',
        imageUrl: LawnTreatmentMonth5Photo,
        text: t('ltrs.orderLawnTreatment.imageSubtitles.monthFive'),
        actionText: '',
        action: { routeName: '' },
      },
      {
        title: '',
        imageUrl: LawnTreatmentMonth11Photo,
        text: t('ltrs.orderLawnTreatment.imageSubtitles.monthEleven'),
        actionText: '',
        action: { routeName: '' },
      },
    ];
  };

  const renderItems = () => {
    return getCarouselItems().map((item, index) => (
      <View key={index} style={style.imagesContainer}>
        <View style={style.smallImagesContainer}>
          <Image source={item.imageUrl as ImageSourcePropType} style={style.smallImage} />
          <Text variant="labelMedium" style={style.smallImageSubtitle}>
            {item.text}
          </Text>
        </View>
      </View>
    ));
  };

  const renderOrderLawnTreatmentContainer = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
    };

    return (
      <StyledCarouselContainer>
        <Slider {...settings}>{renderItems()}</Slider>
      </StyledCarouselContainer>
    );
  };

  if (showSchedule) {
    return (
      <ScrollView>
        <StyledOrderLawnTreatmentInfoContainer>
          <ScheduleLawnTreatment
            price={lawnTreatmentInfo?.options[0].price ?? ''}
            submitRequest={onSubmit}
            isLoading={isUpdating}
          />
        </StyledOrderLawnTreatmentInfoContainer>
      </ScrollView>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {width < 768 ? (
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <StyledOrderLawnTreatmentInfoContainer>
            <View style={style.orderLawnTreatmentContainer}>
              <Text variant="labelLarge" style={style.orderLawnTreatmentSubtitle}>
                {t('ltrs.orderLawnTreatment.subtitle')}
              </Text>

              <Text variant="labelLarge" style={style.orderLawnTreatmentDescription}>
                {t('ltrs.orderLawnTreatment.description')}
              </Text>

              <View style={style.orderLawnTreatmentItems}>
                <Icon name="card-search-outline" size={theme.sizing.s6} />
                <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                  {t('ltrs.orderLawnTreatment.items.certifiedPro')}
                </Text>
              </View>

              <View style={style.orderLawnTreatmentItems}>
                <Icon name="star-circle-outline" size={theme.sizing.s6} />
                <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                  {t('ltrs.orderLawnTreatment.items.guaranteedFreeTouchUps')}
                </Text>
              </View>

              <View style={style.orderLawnTreatmentItems}>
                <Icon name="bone" size={theme.sizing.s6} />
                <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                  {t('ltrs.orderLawnTreatment.items.safeForPetsAndFamilies')}
                </Text>
              </View>

              <Text variant="bodyLarge" style={style.planDetailsTitle}>
                {t('ltrs.orderLawnTreatment.planDetails')}
              </Text>

              <PlanDetailsTable />

              {renderOrderLawnTreatmentContainer()}

              <Text variant="bodySmall" style={style.footer}>
                {t('ltrs.orderLawnTreatment.footer')}
              </Text>
            </View>
          </StyledOrderLawnTreatmentInfoContainer>
        </ScrollView>
      ) : (
        <View>
          <Text variant="headlineLarge">{t('ltrs.orderLawnTreatment.title')}</Text>
        </View>
      )}
      <OrderLawnTreatmentFooter
        price={lawnTreatmentInfo?.options[0].price ?? ''}
        submitRequest={onSubmit}
        isLoading={isUpdating}
      />
    </SafeAreaView>
  );
}
