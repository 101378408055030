import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { AppTheme, setFontWeight } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme) =>
  StyleSheet.create({
    noticeTitle: {
      ...setFontWeight('700'),
      color: theme.colors.primary,
    },
    noticeButton: {
      ...setFontWeight('600'),
      color: theme.colors.blue.primary,
    },
  });

export const StyledFormWrapper = tw.div`
  flex flex-col justify-between gap-y-4 h-full
`;

export const StyledButtons = tw.div`
  flex justify-between gap-x-4 sticky bottom-0 p-4 z-10 bg-whitePrimary
  max-md:bottom-[calc(var(--footer-size))]
`;
