import { useMemo } from 'react';
import { Image, View } from 'react-native';
import LawnTreatmentPhoto from '@lawnstarter/customer-modules/assets/services/lawn_treatment.png';
import { LTRS_FAQ_ITEMS } from '@lawnstarter/customer-modules/constants';
import { getBrand } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Spinner, Text } from '@lawnstarter/ls-react-common/atoms';
import { currency } from '@lawnstarter/ls-react-common/filters';
import { Faq, Question } from '@lawnstarter/ls-react-common/molecules';

import { StyledAboutContainer, stylesLegacy } from './styles';

import type { ImageSourcePropType } from 'react-native';

export function AboutLawnTreatmentLegacy({ showSchedule }: { showSchedule: () => void }) {
  const theme = useAppTheme();
  const style = useMemo(() => stylesLegacy(theme), [theme]);

  return (
    <StyledAboutContainer>
      <Text variant="titleLarge" style={[style.title, style.boldFont]}>
        {t('ltrs.aboutLawnTreatmentLegacy.title')}
      </Text>

      <Image source={LawnTreatmentPhoto as ImageSourcePropType} style={style.image} />

      <ul className="list-disc" style={style.list}>
        <li>
          <Text>{t('ltrs.aboutLawnTreatmentLegacy.bulletPoints.aboutRoots')}</Text>
        </li>
        <li>
          <Text>{t('ltrs.aboutLawnTreatmentLegacy.bulletPoints.aboutWeeds')}</Text>
        </li>
        <li>
          <Text>{t('ltrs.aboutLawnTreatmentLegacy.bulletPoints.aboutGrowth')}</Text>
        </li>
      </ul>

      <Button
        onPress={showSchedule}
        trackID="about_lawn_treatment_screen-next"
        mode="contained"
        style={style.button}
      >
        {t('ltrs.aboutLawnTreatmentLegacy.callToAction')}
      </Button>
    </StyledAboutContainer>
  );
}

export function AboutLawnTreatment({
  showSchedule,
  price,
  submitRequest,
  isLoading = false,
  shouldOrder,
}: {
  showSchedule?: () => void;
  price: string | number;
  submitRequest: () => void;
  isLoading?: boolean;
  shouldOrder?: boolean;
}) {
  const theme = useAppTheme();
  const style = useMemo(() => stylesLegacy(theme), [theme]);

  function renderPrice() {
    return (
      <View style={style.priceContainer}>
        <Text variant="titleLarge" style={[style.boldFont, { fontSize: 36, textAlign: 'center' }]}>
          {price ? (
            t('ltrs.aboutLawnTreatment.priceContainer.amountPerApplication', {
              amount: currency({ amount: price }),
            })
          ) : (
            <Spinner />
          )}
        </Text>
        <Text style={style.pricePeriodicityText}>
          {t('ltrs.aboutLawnTreatment.priceContainer.periodicity')}
        </Text>
        <Button
          onPress={shouldOrder ? submitRequest : showSchedule}
          loading={isLoading}
          trackID="about_lawn_treatment-submit_order"
          mode="contained"
          style={style.priceButton}
        >
          {shouldOrder ? t('orderNow') : t('ltrs.aboutLawnTreatment.priceContainer.callToAction')}
        </Button>
        <Text style={style.priceFooterText}>
          {t('ltrs.aboutLawnTreatment.priceContainer.footer')}
        </Text>
      </View>
    );
  }

  function renderFAQ() {
    const phoneNumber = t(`ltrs.faq.howToGetMoreInformation.phone.${getBrand().slug}`);
    const faqItemsWithPhone = LTRS_FAQ_ITEMS.map((item) => {
      if (item.title === t('ltrs.faq.howToGetMoreInformation.title')) {
        return {
          ...item,
          content: t('ltrs.faq.howToGetMoreInformation.content', { phoneNumber }),
        };
      }
      return item;
    });
    return (
      <View style={style.faqContainer}>
        <Faq header={t('customerFaq.faq')} style={{ wrapperScroll: { height: 330 } }}>
          {
            faqItemsWithPhone.map((item, index) => (
              <Question
                key={`faq:${index}`}
                question={item.title}
                trackID="about_lawn_treatment-ltrs_faq_items"
              >
                <Text style={style.faqInfo}>{item.content}</Text>
              </Question>
            )) as any
            // TODO: Once the Faq component resolves child type and style options remove "as any"
          }
        </Faq>
      </View>
    );
  }
  return (
    <StyledAboutContainer>
      {renderPrice()}
      {!shouldOrder && renderFAQ()}
    </StyledAboutContainer>
  );
}
