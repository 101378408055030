import { SIGNUP_BASE_URL } from '@env';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { ModalViewer } from '@lawnstarter/ls-react-common/organisms';

import { getAndClearLoginIntent } from '@src/helpers';
import { useDynamicSvgImport } from '@src/hooks';

import { StyledHeader, StyledMainContent } from './styles';

import type { User } from '@lawnstarter/ls-react-common/types';

export function GuestTemplate({ user }: { user?: User }) {
  const theme = useAppTheme();
  const location = useLocation();
  const { Svg: Logo } = useDynamicSvgImport('logo');
  const [redirect, setRedirect] = useState<string>('');

  const triggerRedirect = async () => {
    const targetRoute = (await getAndClearLoginIntent()) ?? WebRoutes.browseRedirect;
    setRedirect(targetRoute);
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      triggerRedirect();
    }
  }, [user]);

  if (location.pathname === '/') {
    return <Navigate to={WebRoutes.login} replace />;
  }

  if (redirect && !!redirect.length) {
    return <Navigate to={redirect} replace />;
  }

  return (
    <div className="h-screen flex flex-col justify-between">
      <StyledHeader>
        <Link to={WebRoutes.login}>
          <Logo />
        </Link>
      </StyledHeader>

      <main className="h-full border-primary items-center justify-center flex flex-col">
        <StyledMainContent>
          <Outlet />

          <div className="mt-6 pt-6 mb-1 flex items-center justify-center self-center border-t border-primaryLight">
            <Text style={{ ...setFontWeight('700'), color: theme.colors.primary }}>
              {t('forms.signIn.notRegistered')}
            </Text>

            <Link to={SIGNUP_BASE_URL} className="pl-1 with-hover">
              <Text style={{ color: theme.colors.primary }}>{t('forms.signIn.signUpToOrder')}</Text>
            </Link>
          </div>

          <ModalViewer />
        </StyledMainContent>
      </main>
    </div>
  );
}
